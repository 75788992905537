@import '../../variables';

.sharoushi-registration-card {
    width: 80%;

    form {
        margin: 40px 75px;
    }

    .title, .subtitle {
        text-align: center;
    }

    .title {
        font-size: 18px;
    }

    .subtitle {
        color: $gray-6;
        font-size: 14px;

        &:before {
            content: '* ';
            color: $red-3;
        }
    }

    .buttons {
        width: 300px;
    }

    button.upload-box {
        background: #FAFAFA;
        height: 125px;
        width: 125px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #555;
        border: 1px dashed $button-secondary-outline;
        line-height: 30px;
    }

    .upload-label {
        color: #00000073;
    }

    .upload-form .ant-form-item-control {
        flex-direction: column-reverse;

        button {
            margin-top: 10px;
        }
    }

    .upload-form .ant-btn {
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
    }
}
