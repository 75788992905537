.sharoushi-registration-card {
    width: 100%;

    form {
        margin: 40px 10px;
    }

    .buttons {
        width: 100%;
        margin-bottom: 10px;
    }
}
