@import 'src/assets/styles/variables';

body#labor-social {
    @import 'components/registration_card';

    background-image: url("../../images/bg_login.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .ant-layout {
        background: none;
        color: $button-secondary-color;
    }

    .labor-social-content {
        margin: 135px 175px 100px;
        display: flex;
        align-items: center;
    }

    .register-button {
        position: absolute;
        top: -50px;
        right: 0;
    }
}
