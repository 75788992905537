$border-radius: 2px;

$button-primary-color: #006BB5;
$button-secondary-color: #FFFFFF;
$button-secondary-outline: #D9D9D9;

$gray-1: #F2F2F2;
$gray-2: #F5F5F5;
$gray-3: #E0E0E0;
$gray-4: #999999;
$gray-5: #828282;
$gray-6: #656565;

$yellow-1: #FFF8D4;
$yellow-2: #EFCB0D;
$yellow-3: #E0C015;

$green-1: #F6FFED;
$green-2: #B7EB8F;
$green-3: #66B828;

$purple-1: #F2EEFF;
$purple-2: #BCA7EF;
$purple-3: #7A37A1;

$orange-1: #FFF5EC;
$orange-2: #FFD9AA;
$orange-3: #F4A353;

$brown-1: #F1EBEB;
$brown-2: #B8ABA3;
$brown-3: #843C0C;

$red-1: #FCE6E6;
$red-2: #F59A9B;
$red-3: #FF4D4F;

$light-blue-1: #E6F7FF;
$light-blue-2: #91D5FF;
$light-blue-3: #01A0DD;

$white: #FFFFFF;

$notification-height: 42px;
