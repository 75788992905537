@import 'src/assets/styles/variables';

.labor-social-navbar {
    position: absolute;
    top: 0;
    padding: 5px 136px;
    display: flex;
    width: 100%;
    z-index: 100;
    background-color: $button-primary-color;
    height: 75px;

    .title {
        font-size: 24px;
        color: $button-secondary-color;
        letter-spacing: 1px;
    }
}
