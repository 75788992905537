.meeting-calendar {
    .calendar-header {
        align-items: center;
        padding: 10px;
    }

    .count {
        margin-left: 10px;
    }

    .month-picker {
        text-align: right;

        &-text {
            font-weight: bold;
            font-size: 16px;
        }

        .ant-btn {
            border: none;
            color: black;
        }
    }

    .ant-picker-panel {
        border-top: 1px solid #f0f0f0;
    }

    .meeting-notifications-container {
        height: 120px;
        overflow: auto;
        width: 100%;
    }
}
