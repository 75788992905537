.login-card,
.forgot-password-modal,
.registration-modal {
    width: 95vw !important;
}

.login-card,
.forgot-password-modal,
.registration-modal,
.consultation-decline-modal,
.consultation-cancel-modal,
.change-password-modal,
.submit-schedule-modal {
    form {
        width: 100%;
    }
}

.d-xs-none {
    display: none;
}

.system-notification-container {
    padding: 10px 5% !important;
}
