@import 'src/assets/styles/variables';

body#dashboard {
    @import 'components/registration_card';
    @import 'components/meeting_calendar';
    @import 'components/consultation';

    background: $white;

    .ant-layout {
        min-height: 100vh;
    }

    .down-icon {
        line-height: 24px;
        float: right;
        font-size: 14px;
    }

    .ant-layout-sider {
        position: fixed;
        height: 100vh;

        background-color: #1B2226;

        .ant-menu {
            background: none;
        }

        .ant-menu-item:first-child {
            margin-top: 0;
        }

        .bottom-link {
            position: absolute;
            bottom: 0;
            width: 100%;

            .ant-btn {
                background: none;
                border: none;
                text-align: left;
                color: white;
                padding: 5px 20px;
                margin-bottom: 10px;
            }
        }
    }

    .ant-layout-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .ant-layout-header, .ant-layout-content {
        margin-left: 300px;
    }

    .ant-layout-header {
        width: calc(100% - 300px);
    }

    .ant-layout-content {
        margin-top: 64px;
        background-color: $white;
    }

    .sider-title, .ant-menu-item, .ant-menu-item a {
        color: $gray-2;
    }

    .sider-title {
        height: 64px;
        line-height: 64px;
        text-align: center;
        border-bottom: 1px solid;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        &, & a {
            color: #1890FF;
        }
    }

    .ant-menu-inline {
        border-right: none;
    }

    .ant-menu-item {
        width: 100%;

        & :after {
            right: 1px;
        }
    }

    .ant-layout-header {
        background: #006BB5;

        .buttons {
            float: right;

            button {
                border: none;
                background: none;
                color: $white;
            }
        }
    }

    .ant-layout-content {
        padding: 40px;
    }

    .sharoushi-registration-card {
        width: 100%;
    }

    .dashboard-content {
        .ant-select-selection-item {
            color:black !important
        }
        
        .ant-checkbox-wrapper-checked > span {
            color:black !important
        }

        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .section-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .section-description {
            font-weight: normal;
            font-size: 13px;
            color: $gray-4;
        }
        
        .change-password {
            color: #01A0DD;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }

    .profile {
        .edit-button {
            width: 100px;
            position: absolute;
            right: 40px;
            top: 30px;
        }

        .upload-preview {
            border: 1px solid #D9D9D9;
            border-radius: 5px;
            padding: 5px;
            height: 100px;
            min-width: 100px;
        }

        .delete-profile-image {
            color: $white !important;
            position: absolute;
            bottom: 0px;
            left:160px;
        }

        .buttons {
            width: 300px;
        }
    }
}
