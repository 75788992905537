body#dashboard {
    @import 'components/registration_card';
    @import 'components/meeting_calendar';
    @import 'components/consultation';

    .ant-layout-content {
        margin-left: 0;
    }

    .ant-layout-header {
        margin-left: 0;
        width: 100%;
    }

    .ant-layout-sider {
        z-index: 1000;
    }

    .profile {
        .edit-button {
            position: inherit;
            margin-top: 10px;
        }

        form {
            margin: 40px 0;
        }

        .buttons {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
